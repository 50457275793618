import React from 'react'

import Layout from '../components/layout'
import Column from '../components/column'
import Row from '../components/row'
import Text from '../components/text'
import { COLORS } from '../assets/theme'
import Title from '../components/title'
import Border from '../components/border'

const AboutPage = () => {
  return (
    <Layout>
      <Column marginTop='5' alignItems='center'>
        <Column>
          <Title
            as='h2'
            color={COLORS.fontWhite}
            fontSize='2.2em'
            lineHeight='1.15'
            textAlign='center'
          >
            About
          </Title>
          <Border
            alignSelf='center'
            height='2px'
            width='50px'
            marginBottom='10px'
            borderRadius='2px'
          />
        </Column>
        <Row justifyContent='center' maxWidth='600px' paddingTop='4'>
          <Text textAlign={['center', 'left']} fontSize='3' paddingX='4px'>
            With over 30 years of martial arts training, including over 20 years
            in Brazilian Jiu-Jitsu (BJJ), Vittorio Lemos is a 2nd degree black
            belt who is fully committed to the teachings of BJJ, self-defense,
            and street readiness.
            <br />
            <br />
            Mr. Lemos first began training in Judo during the early ‘80s in
            Niteroi, Rio de Janeiro Brasil, under Sensei Marco Aurelio. After
            dabbling in Capoeira, Muy Thai, and other martial arts, he started
            training in Jiu-Jitsu & self-defense in the early ‘90s. He would
            eventually land himself under Master Carlos Gracie Jr. of Barra
            Gracie. His other teachers include Professor Roberto Maia, Professor
            Jean Vandesteen, and Grand Master Relson Gracie.
            <br />
            <br />
            Mr. Lemos holds a number of certifications and training under
            multiple BJJ masters and boasts an impressive record in
            IBJJF-organized tournaments. As a 3x gold medalist and silver
            medalist in the super heavyweight division and a gold medalist in
            the open weight division. Mr. Lemos currently coaches BJJ to people
            of all ages and walks of life: children to law enforcement
            professionals, white belt to black belt.
          </Text>
        </Row>
      </Column>
    </Layout>
  )
}

export default AboutPage
