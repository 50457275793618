import styled from '@emotion/styled'
import {
  flex,
  flexWrap,
  flexbox,
  gridGap,
  space,
  layout,
  color,
  backgroundColor,
  border,
  borderBottom,
  borderTop,
  position,
  height,
  width,
} from 'styled-system'
import type {
  FlexProps,
  FlexWrapProps,
  FlexboxProps,
  GridGapProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  BorderProps,
  BorderBottomProps,
  BorderTopProps,
  PositionProps,
  BackgroundColorProps,
  HeightProps,
  WidthProps,
} from 'styled-system'
import { COLORS } from '../../assets/theme'

type BorderType = FlexProps &
  FlexboxProps &
  FlexWrapProps &
  GridGapProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  BorderProps &
  BorderBottomProps &
  BorderTopProps &
  PositionProps &
  BackgroundColorProps &
  HeightProps &
  WidthProps

const Border = styled('div')<BorderType>(
  flex,
  flexbox,
  flexWrap,
  gridGap,
  space,
  layout,
  color,
  border,
  borderBottom,
  borderTop,
  position,
  backgroundColor,
  height,
  width
)

Border.defaultProps = {
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: COLORS.secondary,
  height: '1px',
}

export default Border
